import './App.css';
import React, { createContext , useState } from 'react';
import Login from './Screens/Login';
import DailySummary from './DailySummary/Daily';
import MonthlySummary from './MonthlySummary/Monthly';
import TableBuilder from './TableBuilder/Table';
import ChartBuilder from './ChartBuilder/ChartBuilder';
import Cohorts from './Screens/Cohorts';
import Budgets from './Budgets/Budgets';
import Chat from './Screens/Chat';
import BudgetTable from './Budgets/BudgetTable';
import PpcTest from './PPC/PpcTest';
import PPCCampaign from './PPC/PPCCampaign';
import PPCAdGroup from './PPC/PPCAdGroup';
import PPCKwBubble from './PPC/PPCKwBubble';
import PPCKwTreeScreen from './PPC/PPCKwTreeScreen';
import SocialCampaign from './Paid Social/SocialCampaign';
import SocialAdGroup from './Paid Social/SocialAdGroup';
import AnalyticsMonthly from './Analytics/AnalyticsMonthly';
import AnalyticsTable from './Analytics/AnalyticsTable';
import AttributionComparison from './Attribution/AttributionComparison';
import AttributionDimensions from './Attribution/AttributionDimensions';
import ProgrammaticChannel from './Programmatic/ProgrammaticChannel';
import ProgrammaticPub from './Programmatic/ProgrammaticPub';
import ProgrammaticType from './Programmatic/ProgrammaticType';
import DashCustom from './DashBuilder/DashCustom';
import Yearly from './Yearly/Yearly';
import Options from './Options/Options';
import PPT from './PPT/PPT';
import { themes } from './themes'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingComponent from './Components/Loader'

function App() {

  const { user, isAuthenticated, isLoading } = useAuth0();
  const [themeType , setTheme] = useState((localStorage.getItem('mode')) ?? 'white')
  const [clientID, setClientID] = useState((localStorage.getItem('client')) ?? 'Rank')
  const clientList = ['AFUN', 'DMJ', 'FM', 'Rank']

  function configHandler (props) {
    const mode = localStorage.setItem("mode", props)
    
    setTheme(props)
  }

  function clientHandler (props) {
    const mode = localStorage.setItem("client", props)
    console.log(props)
    setClientID(props)
  }

  const theme = themes[themeType]

  if (isLoading) {
    return <LoadingComponent theme={theme} />;
  }

  if (!isAuthenticated) {
    return <Login theme={theme}/>
  }

  const userProfile = user
  const userRole = userProfile?.stack_user_metadata?.role || 'guest'
  const userClient = userProfile?.stack_user_metadata?.client || 'guest'

  const clientIdentifier = userRole === 'admin' ? clientID : userClient;

  const configArray = {
    role : userRole,
    clientID: clientIdentifier,
    configHandler,
    clientHandler
  }

  return (
    <Router basename={'/pattern-testing'}>
    <Routes>
    <Route exact path="/" element={<PpcTest theme={theme} configArray={configArray} themeType={themeType} />}/>
    <Route path="/login" element={<Login theme={theme} themeType={themeType}/>}/>
    <Route exact path="/daily" element={<DailySummary theme={theme} configArray={configArray}/>}/>
    <Route path="/monthly" element={<MonthlySummary theme={theme} configArray={configArray}/>}/>
    <Route path="/table" element={<TableBuilder theme={theme} configArray={configArray}/>}/>
    <Route path="/chart" element={<ChartBuilder theme={theme} configArray={configArray}/>}/>    
    <Route path="/cohorts" element={<Cohorts theme={theme} configArray={configArray}/>}/>
    <Route path="/ppc_campaign" element={<PPCCampaign theme={theme} configArray={configArray}/>}/>
    <Route path="/ppc_adgroup" element={<PPCAdGroup theme={theme} configArray={configArray}/>}/>
    <Route path="/ppc_bubble" element={<PPCKwBubble theme={theme} configArray={configArray}/>}/>
    <Route path="/ppc_tree" element={<PPCKwTreeScreen theme={theme} configArray={configArray}/>}/>
    <Route path="/budget" element={<Budgets theme={theme} configArray={configArray}/>}/>
    <Route path="/budget_table" element={<BudgetTable theme={theme} configArray={configArray}/>}/>
    <Route path="/chat" element={<Chat theme={theme} configArray={configArray}/>}/>
    <Route path="/analytics" element={<AnalyticsMonthly theme={theme} configArray={configArray}/>}/>
    <Route path="/analytics_table" element={<AnalyticsTable theme={theme} configArray={configArray}/>}/>
    <Route path="/attribution_comparison" element={<AttributionComparison theme={theme} configArray={configArray}/>}/>
    <Route path="/attribution_dimensions" element={<AttributionDimensions theme={theme} configArray={configArray}/>}/>
    <Route path="/social_campaign" element={<SocialCampaign theme={theme} configArray={configArray}/>}/>
    <Route path="/social_adgroup" element={<SocialAdGroup theme={theme} configArray={configArray}/>}/>
    <Route path="/yearly" element={<Yearly theme={theme} configArray={configArray}/>}/>
    <Route path="/ppt" element={<PPT theme={theme} configArray={configArray}/>}/>
    <Route path="/prog_url" element={<ProgrammaticPub theme={theme} configArray={configArray}/>}/>
    <Route path="/prog_type" element={<ProgrammaticType theme={theme} configArray={configArray}/>}/>
    <Route path="/prog_channel" element={<ProgrammaticChannel theme={theme} configArray={configArray}/>}/>
    <Route path="/dash_custom" element={<DashCustom theme={theme} configArray={configArray}/>}/>
    <Route path="/options" element={<Options 
                                        theme={theme} 
                                        configArray={configArray}
                                        configHandler={configHandler}
                                        clientHandler={clientHandler}
                                        userProfile={userProfile}
                                        clientList={clientList}
                                        />}/>
    </Routes>
    </Router>
  );
}

export default App;
