import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { CubeProvider } from '@cubejs-client/react'; 
import cubejs from '@cubejs-client/core';

const cubejsApi = cubejs( 
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTc4MTk5NjF9.U4NLmSfV1xrfAqIBnrodH_97XlJpTiLKGhP9rObX3Ck', 
  { apiUrl: 'https://muddy-warbler.gcp-europe-west3-a.cubecloudapp.dev/cubejs-api/v1' } 
); 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

  <Auth0Provider
    domain="dev-hldt17c2gz1xk402.eu.auth0.com"
    clientId="j43atrKvQfA76PN4HAMkQmrkDbNSL1Sf"
    authorizationParams={{
      redirect_uri: 'https://pattern.stackdigital.eu/daily'
    }}
  >
      <CubeProvider cubejsApi={cubejsApi}>
    <App />
</CubeProvider>
    </Auth0Provider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
